@import "variables";

.container {
    max-width: 100%;
    padding: 0 5%;
    width: 1720px;
    margin: 0 auto;
    display: block;

    &.container-small {
        width: 1280px;
    }
}

.maven {
    font-family: "Maven Pro", sans-serif;
}

.title {
    &-default {
        font-size: 60px;
        font-weight: 500;
        line-height: 1;

        b {
            font-weight: 700;
        }
        strong {
            font-weight: 700;
            display: block;
            white-space: nowrap;
        }
        span {
            display: block;
            white-space: nowrap;
        }
    }

    &-mini {
        font-size: 18px;
        text-transform: uppercase;
        letter-spacing: 10px;
        font-weight: 700;
    }

    &--center {
        text-align: center;
    }
    &--start {
        text-align: left;
    }
    &--end {
        text-align: right;
    }
    &--white {
        color: #ffffff;
    }
    &--gray {
        color: $text-color-secondary;
    }
    &--red {
        color: $color-primary;
    }
}

.btn {
    height: 64px;
    border-radius: 32px;
    border: 3px solid transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 240px;
    padding: 0 50px;

    span {
        font-family: "Maven Pro", sans-serif;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 250ms ease-in-out;
    }

    &.btn-default {
        background: $color-primary;
        border-color: $color-primary;

        span {
            color: #fff;
        }

        &:hover {
            background: $color-secondary;
            border-color: $color-secondary;
        }
    }

    &.btn-white {
        background: #ffffff;
        border-color: #ffffff;

        span {
            color: $text-color-primary;
        }

        &:hover {
            background: $color-secondary;
            border-color: $color-secondary;

            span {
                color: #fff;
            }
        }
    }

    &.btn-transparent {
        background: rgba(255, 255, 255, 0.2);
        border-color: transparent;

        span {
            color: #ffffff;
        }

        &:hover {
            background: #ffffff;

            span {
                color: $color-primary;
            }
        }
    }

    &.btn-outline {
        background: transparent;
        border-color: #ffffff;

        span {
            color: #ffffff;
        }

        &:hover {
            background: #ffffff;

            span {
                color: $color-primary;
            }
        }

        &.btn-outline--gray {
            border-color: $text-color-secondary;

            span {
                color: $text-color-secondary;
            }

            &:hover {
                background: $text-color-secondary;

                span {
                    color: $text-color-primary;
                }
            }
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &-control {
        flex: 0 0 100%;
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;

        &.half {
            flex: 0 0 49%;
            width: 49%;
        }

        &.third {
            flex: 0 0 32.33%;
            width: 32.33%;
        }

        &.quad {
            flex: 0 0 24%;
            width: 24%;
        }

        input {
            flex-grow: 1;
            outline: 0;
            margin: 0;
        }

        select {
            -webkit-appearance: none;
            appearance: none;
            font-size: 16px;
            border-radius: 0px;
            outline: 0;
            border: 0;
            background: transparent;
            flex-grow: 1;
        }

        &--dark {
            input,
            textarea,
            select {
                color: #fff;
            }
        }

        &__warn {
            color: $danger;
        }

        &__select {
            position: relative;
            &::before {
                content: "\f078";
                position: absolute;
                top: 50%;
                right: 16px;
                pointer-events: none;
                transform: translate(0, -50%);
                font-family: "Font Awesome 5 Free";
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-size: 16px;
                color: $color-primary;
                font-weight: 900;
            }

            &.selected {
                select {
                    color: $text-color-primary !important;
                }
            }
        }
    }

    &.form-newsletter {
        .form-control {
            background: rgba($color: $text-color-secondary, $alpha: 0.5);
            border-bottom: 3px solid $text-color-secondary;
            margin-bottom: 30px;

            input {
                font-weight: 300;
                padding: 20px;
                color: #ffffff;
                font-size: 16px;
            }

            &:focus-within {
                border-color: $color-primary;
            }
        }
    }

    &.form-contato {
        .form-control {
            border-bottom: 1px solid $text-color-secondary;
            margin-bottom: 20px;

            &:focus-within {
                border-color: $color-primary;
            }

            input {
                font-weight: 500;
                font-size: 16px;
                padding: 20px 0;

                &::placeholder {
                    color: $text-color-secondary;
                }
            }
            select {
                padding: 20px 0;
                font-weight: 500;
                color: $text-color-secondary;
            }
        }
        .textarea-control {
            display: flex;
            flex-flow: row;
            align-items: flex-end;
            width: 100%;
            flex: 0 0 100%;

            textarea {
                flex-grow: 1;
                border-bottom: 1px solid $text-color-secondary;
                outline: 0;
                margin: 0;
                resize: none;
                font-weight: 500;
                font-size: 16px;
                padding: 20px 0;
                color: $text-color-secondary;
                min-height: 140px;
            }

            .btn {
                margin-left: 24px;
                border-color: $text-color-secondary;

                span {
                    color: $text-color-secondary;
                }

                &:hover {
                    background: $text-color-secondary;

                    span {
                        color: $text-color-primary;
                    }
                }
            }
        }
    }
}

.banner-services,
.videos {
    .swiper.s-wrapper {
        .swiper-pagination-bullets {
            margin-top: 65px;
            position: relative;
            text-align: left;

            .swiper-pagination-handle {
                margin: 0 10px 0 0;
                padding: 0;
                outline: 0;

                .swiper-pagination-bullet {
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #ffffff;
                    opacity: 1;

                    &.swiper-pagination-bullet-active {
                        opacity: 0.4;
                    }
                }
            }
        }
    }
}

.blog {
    &-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 90px;
    }

    &-item {
        flex: 0 0 calc((100% / 3) - 60px);
        width: calc((100% / 3) - 60px);

        &:hover {
            text-decoration: none;

            .blog-item {
                &__image {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
        }

        &__image {
            position: relative;
            min-width: 100%;
            margin-bottom: 30px;
            overflow: hidden;

            img {
                width: 100%;
                display: block;
                max-height: 280px;
                object-fit: cover;
                transition: all 250ms ease-in-out;
            }
        }

        &__title {
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 30px;
        }

        &__description {
            font-size: 16px;
            font-weight: 300;
        }

        &__date {
            margin-top: 30px;
            position: relative;

            span {
                font-size: 14px;
                font-weight: 500;
                color: $text-color-secondary;
                position: relative;
                z-index: 2;
                padding-right: 8px;
                background: #fff;
            }

            &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 0;
                width: 100%;
                height: 3px;
                background: $text-color-secondary;
            }
        }

        &.blog-item--light {
            .blog-item {
                &__title {
                    color: #ffffff;
                }

                &__description {
                    color: #ffffff;
                }

                &__date {
                    span {
                        background: $text-color-primary;
                    }
                }
            }

            &:hover {
                background: $text-color-primary;

                .blog-item {
                    &__date {
                        span {
                            background: $text-color-primary;
                        }
                    }
                }
            }
        }
    }
}

.clients {
    padding: 200px 0 60px;

    .title-mini {
        margin-bottom: 30px;
    }

    &__description {
        max-width: 600px;
        font-size: 18px;
        margin: 40px auto 0;
        text-align: center;
    }

    &-item {
        min-width: 230px;
        position: relative;

        img {
            display: block;
            width: 230px;
            object-fit: contain;
        }
    }

    &-list {
        margin-top: 70px;
        position: relative;
        overflow: hidden;

        &::before {
            content: "";
            background: linear-gradient(
                    270deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.5) 57%,
                    rgba(255, 255, 255, 1) 100%
                )
                0% 0% no-repeat;
            position: absolute;
            left: 0;
            top: 0;
            width: 300px;
            height: 100%;
            z-index: 9;
        }
    }

    &-arrows {
        margin-top: 60px;
        text-align: right;

        &__item {
            height: 50px;
            width: 50px;
            border: 2px solid $text-color-primary;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            outline: 0;

            & + .clients-arrows__item {
                margin-left: 20px;
            }

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                display: block;
                transition: all 250ms ease-in-out;
                object-position: center center;
            }

            &:hover {
                background: $text-color-primary;

                img {
                    filter: brightness(0) invert(1);
                }
            }

            &.inactive {
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }
}

.feedbacks {
    background-color: #3f4041;
    background-image: url(../assets/background-depoimentos.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 180px 0 100px;

    .wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;

        .col {
            // flex: 0 0 calc(50% - 70px);
            // width: calc(50% - 70px);

            &:first-child {
                hr {
                    margin: 16px 0;
                    display: block;
                    max-width: 170px;
                    border: 0;
                    margin-left: auto;
                    border-bottom: 3px solid $color-primary;
                }
            }

            & + .col {
                max-width: 550px;
                margin-left: 60px;
            }
        }
    }

    .title-mini {
        margin-bottom: 30px;
    }

    &__description {
        text-align: right;
        color: #ffffff;
        margin-top: 30px;
        max-width: 360px;
        margin-left: auto;
    }

    &-item {
        &__description {
            padding: 50px 40px;
            background: #ffffff;
            color: $text-color-primary;
            line-height: 40px;
        }

        &__position {
            font-weight: 700;
            font-size: 30px;
            color: #ffffff;
        }

        hr {
            margin: 16px 0;
            display: block;
            max-width: 170px;
            border: 0;
            border-bottom: 3px solid $color-primary;
        }

        .author {
            display: flex;
            flex-flow: row;
            align-items: center;

            &__avatar {
                min-width: 86px;
                min-height: 86px;
                position: relative;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 30px;

                img {
                    display: block;
                    width: 86px;
                    height: 86px;
                    object-fit: cover;
                }
            }

            &__name {
                font-size: 18px;
                font-weight: 700;
                color: #fff;
            }

            &__occupation {
                color: #ffffff;
                margin-top: 14px;
                display: block;
            }
        }
    }

    .content {
        margin-top: 30px;
    }

    &-arrows {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin-left: 30px;

        &__item {
            height: 50px;
            width: 50px;
            border: 2px solid #ffffff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            user-select: none;
            outline: 0;

            & + .feedbacks-arrows__item {
                margin-top: 20px;
            }

            img {
                width: 24px;
                height: 24px;
                object-fit: contain;
                display: block;
                transition: all 250ms ease-in-out;
                object-position: center center;
                filter: brightness(0) invert(1);
            }

            &:hover {
                background: #ffffff;

                img {
                    filter: unset;
                }
            }

            &.inactive {
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }
}

.pagination {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    &__item {
        margin: 0 8px;
    }

    &__link {
        font-size: 14px;
        color: $text-color-secondary;
        font-weight: 500;

        &.active {
            font-weight: 700;
            font-size: 18px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .title {
        &-mini {
        }
        &-default {
            font-size: 44px;

            span {
                white-space: inherit;
            }
        }
    }

    .form {
        &-control {
            &.half,
            &.third,
            &.quad {
                flex: 0 0 100%;
                width: 100%;
            }
        }

        &.form-contato {
            .textarea-control {
                flex-flow: column;

                textarea {
                    width: 100%;
                }

                .btn {
                    margin-top: 24px;
                    margin-left: 0px;
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .clients {
        padding: 80px 0 60px;

        &-list {
            padding: 0 5%;
            &::before {
                display: none;
            }
        }

        &-arrows {
            text-align: center;
        }
    }

    .feedbacks {
        padding: 90px 0 50px;
        background-image: inherit;

        .title {
            text-align: left;
        }

        &__description {
            text-align: left;
        }

        &-item {
            &__description {
                padding: 5%;
                max-height: 300px;
                overflow-y: scroll;
            }
        }

        &-arrows {
            margin-top: 30px;
            margin-left: 0px;
            flex-flow: row;

            &__item {
                & + .feedbacks-arrows__item {
                    margin-top: 0px;
                    margin-left: 10px;
                }
            }
        }
    }

    .blog {
        &-item {
            flex: 0 0 100%;
            width: 100%;

            & + .blog-item {
                margin-top: 32px;
            }
        }

        &-list {
            gap: 0px;
        }
    }
}
