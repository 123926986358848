// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$telmaster-primary: mat-palette(
  (
    50: #f6e3e5,
    100: #e9b9be,
    200: #db8b93,
    300: #cd5d67,
    400: #c23a47,
    500: #b71726,
    600: #b01422,
    700: #a7111c,
    800: #9f0d17,
    900: #90070d,
    A100: #ffbebf,
    A200: #ff8b8d,
    A400: #ff585b,
    A700: #ff3e43,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #ffffff,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #000000,
      A400: #000000,
      A700: #ffffff,
    ),
  )
);
$telmaster-accent: mat-palette(
  (
    50: #ebebeb,
    100: #cdcdce,
    200: #acacad,
    300: #8a8a8c,
    400: #717173,
    500: #58585a,
    600: #505052,
    700: #474748,
    800: #3d3d3f,
    900: #2d2d2e,
    A100: #8c8cf3,
    A200: #5e5eef,
    A400: #1a1aff,
    A700: #0101ff,
    contrast: (
      50: #000000,
      100: #000000,
      200: #000000,
      300: #000000,
      400: #ffffff,
      500: #ffffff,
      600: #ffffff,
      700: #ffffff,
      800: #ffffff,
      900: #ffffff,
      A100: #000000,
      A200: #ffffff,
      A400: #ffffff,
      A700: #ffffff,
    ),
  )
);

// The warn palette is optional (defaults to red).
$telmaster-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$telmaster-theme: mat-light-theme($telmaster-primary, $telmaster-accent, $telmaster-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($telmaster-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
}