@import "variables";
@import "reset";
@import "admin";
@import "global";
@import "~swiper/swiper-bundle.min.css";

router-outlet ~ * {
    position: absolute;
    height: 100%;
    width: 100%;
}
